@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto';
  src: url(assets/fonts/Roboto/Roboto-Light.ttf);
  font-weight: 200;
}

@font-face {
  font-family: 'Roboto';
  src: url(assets/fonts/Roboto/Roboto-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url(assets/fonts/Roboto/Roboto-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url(assets/fonts/Roboto/Roboto-Bold.ttf);
  font-weight: 700;
}

$brandOrange600: #f87474;
$brandOrange700: #fc6566;
$brandSky700: #799bba;
$darkSky: #546c82;
$gray200: #e5e7eb;
$gray111: #111;
$gray333: #333;
$gray555: #333;
$white: #fff;

* {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

// CUSTOM CLASSES

.customLinkHeader {
  font-size: 14px;
  font-weight: 400;
  color: $gray111;
  &.active {
    color: $brandOrange700;
  }
}

.customCompanyLocationSwitcher {
  font-size: 14px;
  font-weight: 400;
  color: $gray111;
}

.customHeaderUserDropDown {
  font-size: 14px;
  font-weight: 400;
  color: $gray111;
}

.customParagraph {
  font-size: 14px;
  font-weight: 400;
  color: $gray111;
  &.medium-size {
    font-size: 16px;
  }
  &.white {
    color: $white;
  }
}

.customHead1 {
  font-size: 30px;
  font-weight: 700;
  color: $white;

  &.gray {
    color: $gray111;
  }
}

.customHead2 {
  font-size: 18px;
  font-weight: 500;
  color: $gray111;
}

.customHead3 {
  font-size: 16px;
  font-weight: 500;
  color: $gray111;

  &.white {
    color: $white;
  }

  &.smaller {
    font-size: 14px;
  }
}

.customHead4 {
  font-size: 15px;
  font-weight: 400;
  color: $gray333;
}

.customUl {
  li {
    font-size: 14px;
    font-weight: 400;
    color: $gray333;
    &.strong {
      font-size: 16px;
    }
  }
}

.customInput {
  font-size: 14px;
  font-weight: 400;
  color: $gray111;
}

.customLabel {
  font-size: 14px;
  font-weight: 400;
  color: $gray333;
}

.customSelectInput {
  font-size: 14px;
  font-weight: 400;
  color: $gray111;
}

.customTableChip {
  font-size: 14px;
  font-weight: 400;
  color: $white;
  background-color: $brandSky700;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 0.375rem;
}

.customInfoBox {
  p {
    font-size: 16px;
    font-weight: 400;
    color: $gray555;
    span {
      font-size: 16px;
      font-weight: 500;
      color: $gray111;
    }
  }
}

.customAlert {
  font-size: 14px;
  font-weight: 400;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 0.5rem;
}

.selected {
  color: 'red';
  background-color: 'red';
}
// UI COMPONENTS
.main-btn {
  background-color: $brandOrange700;
  text-transform: none;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  color: $white;

  a {
    font-weight: 300;
  }

  &.border {
    border: 1px solid $brandOrange700;

    &:hover {
      border: 1px solid $brandOrange600;
    }
  }

  &.disabled {
    border: 1px solid $gray200;
  }

  &.processing {
    background-color: $brandOrange700;
    border: none;
    color: $white;
  }

  &:hover {
    box-shadow: none;
    background-color: $brandOrange600;
  }
}

.calendarEvent {
  background-color: #e55b3c;
  border: 1px solid #fff !important;
}

// CUSTOM CLASSES
.custom-shadow {
  box-shadow: 0 2px 3px rgb(0 0 0 / 7%);
}

.secondary-btn {
  color: $brandOrange700;
  border: 1px solid $brandOrange700;
  background-color: #fff;
  text-transform: none;
  box-shadow: none;
  font-size: 14px;
  font-weight: 300;

  &.border {
    border: 1px solid $brandOrange700;
  }

  &.disabled {
    border: 1px solid $gray200;
  }

  &.processing {
    background-color: $brandOrange700;
    border: 1px solid $brandOrange700;
    color: $white;
  }

  &:hover {
    box-shadow: none;
    background-color: #fff;
    color: $brandOrange700;
    border: 1px solid $brandOrange700;
  }
}
.removeCerticifateBtn {
  margin-left: 0px !important;
}

// SELECT AUTOMATION
.custom-select-input {
  .css-13cymwt-control {
    border-color: rgb(224, 224, 224) !important;
  }

  .css-t3ipsp-control {
    box-shadow: none !important;
    border-color: #bfdbfe !important;

    &:hover {
      border-color: #bfdbfe !important;
    }
  }

  .css-1dimb5e-singleValue,
  .css-10wo9uf-option {
    font-size: 14px !important;
  }
  .css-10wo9uf-option:hover {
    background-color: red !important;
  }

  input {
    width: 100% !important;
  }

  [type='text']:focus {
    box-shadow: none !important;
  }
}
.rbc-month-view {
  height: 100vh;
}

.examination-div {
  border: 1px solid #214261;
  border-radius: 5px;
}
.selected {
  border: 1px solid #214261;
  background-color: #f0f8ff;
}

// TOOLTIP
[data-tooltip] {
  position: relative;

  &::before {
    content: attr(data-tooltip);
    position: absolute;
    display: none;
    right: 0;
    bottom: 100%;
    padding: 4px 8px;
    background-color: #444;
    color: #fff;
    font-size: 12px;
    border-radius: 3px;
    width: max-content;
    max-width: 250px;
    text-align: left;
  }

  &:hover::before {
    display: block;
  }

  &.tooltip-bottom {
    &::before {
      top: 100%;
      bottom: 0px;
      height: fit-content;
      z-index: 2;
    }
  }
}

// MUI TABLE

.PrivateSwitchBase-input {
  width: 100%;
  height: 100%;
}

.rmFilterBtn {
  display: flex;
  align-items: center;
  color: #f87474;
  font-weight: 500;
  padding: 4px 5px;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
}

.MuiDataGrid-cellContent-alignTop {
  .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    align-items: start;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.MuiCheckbox-root {
  align-self: start;
}

// MUI DATAGRID FIRST CELL LINK
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:nth-child(1),
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:nth-child(2),
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:nth-child(3),
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:nth-child(4),
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:nth-child(5) {
  a:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

// MUI DATAGRID FILTER

.MuiFormControl-root {
  input:nth-child(1) {
    padding: 4px 0 5px;
  }
  [type='text']:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  select:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}

// DATEPICKER

.custom-datepicker-input {
  .Mui-focused {
    fieldset {
      border-color: #bfdbfe !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    border-color: rgb(224, 224, 224) !important;
    outline: none;

    &:focus-visible,
    &:focus {
      border: none;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    border: none;
    width: 100%;
    &:focus-visible,
    &:focus {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }

    input {
      border: none;
      outline: none;
      font-size: 14px;
      padding: 0.5rem 0.75rem !important;
      &:focus,
      &:focus-visible {
        border: none !important;
        outline: none !important;
        box-shadow: none;
      }
    }
  }

  /*.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-color: rgb(224, 224, 224) !important;
    &:hover {
      border-color: rgb(224, 224, 224) !important;
    }
  }*/
}

// FULLCALENDAR

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  flex-wrap: wrap-reverse;
}

// // MUI TOOLBAR
// .css-1j9kmqg-MuiDataGrid-toolbarContainer {
//   padding: 5px 10px !important;
// }

// .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
//   color: $brandOrange600 !important;
// }

// .MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd.css-c63i49-MuiInputBase-input-MuiInput-input:focus {
//   --tw-ring-color: none !important;
// }

// .css-v4u5dn-MuiInputBase-root-MuiInput-root::after {
//   border-bottom: none !important;
// }

// Globally removes inner input box shadow from all input fields (in our case, MUI input fields and React Select input fields)
input:focus {
  box-shadow: none !important;
}

.change-duration-button {
  &.arrow-down {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #555;
  }
  &.arrow-up {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #555;
  }
}
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none;
}
.fc-event-main {
  padding: 0 !important;
  overflow: hidden;
}
.fc-timegrid-slot-lane {
  background-color: rgba(85, 236, 85, 0.163);
}

.fc-non-business {
  background: #fff !important;
  opacity: 0.7 !important;
}

.dashboard-page {
  .card-holder {
    background-color: #214261;
    border-radius: 25px;
    padding: 10px;
    .card {
      padding: 15px;
      border-radius: 25px;
      p {
        color: #fff;
        &.number {
          font-size: 70px;
          line-height: 60px;
          font-weight: 700;
        }
        &.bottomText {
          font-size: 21px;
          font-weight: 200;
          line-height: 29px;
        }
        &.topText {
          font-size: 32px;
          line-height: 29px;
          font-weight: 700;
          text-transform: capitalize;
          margin-bottom: 1px;
        }
      }
    }
  }
}

.single-customer-page-holder {
  .css-19kzrtu {
    padding: 0;
  }
  .custom-tab-panel {
    button {
      font-size: 16px;
      font-weight: 400;
      color: #111;
      text-transform: none;
    }
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
      color: #fc6566;
    }
  }
}

.customScheduleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4px;
  justify-content: space-evenly;
}

.customScheduleWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.status-chip {
  font-size: 10px;
  line-height: 10px;
  color: #555;
  font-weight: 700;
  border-radius: 6px;
  border: 1px solid #555;
  text-transform: uppercase;
  padding: 2px 5px;
  margin-top: 2px;
  display: block;
  &.overdue {
    color: #fff;
    background-color: #cb0100;
    border-color: #cb0100;
  }
  &.due {
    color: #fff;
    background-color: #e0ad12;
    border-color: #e0ad12;
  }
  &.scheduled {
    color: #fff;
    background-color: #7bae22;
    border-color: #7bae22;
  }
}
